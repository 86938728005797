import React from 'react';
import PropTypes from 'prop-types';
import { HTMLTable, H5 } from '@blueprintjs/core';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import styles from './TaskResult.module.css';


function countJtiPos(stats, q, resultData) {
  if (/бокс.*джудо/i.test(q.text)) {
    stats.boxJudo.pos += resultData ?? 0;
  } else if (/шкаф/i.test(q.text)) {
    stats.shelf.pos += resultData ?? 0;
  } else if (/бокс/i.test(q.text)) {
    stats.box.pos += resultData ?? 0;
  } else if (/джудо/i.test(q.text)) {
    stats.judo.pos += resultData ?? 0;
  }
}

function countJtiSku(sectionStats, q, resultData) {
  const sku = q.swipesubtitle || q.subsubtitle || q.subtitle;
  if (!sectionStats.sku.includes(sku)) {
    sectionStats.sku.push(sku);
  }
  sectionStats.skuValue += resultData ?? 0;
}

function computeJtiStats(task) {
  const stats = _.fromPairs(['shelf', 'box', 'judo', 'boxJudo'].map(k => [k, {
    pos: 0,
    sku: [],
    skuValue: 0,
  }]));

  const resultById = _.fromPairs(_.map(task.result.questions, r => [r.id, r]));

  for (const q of task.data.questions) {
    const resultData = resultById[q.id]?.data;
    if (_.isNil(resultData) || !['NUMBER', 'INTEGER'].includes(q.result.type)) {
      continue;
    }

    if (/^Кассы/.test(q.code)) {
      countJtiPos(stats, q, resultData);
      continue;
    }

    const skuQuestion = (
      q.title.toLowerCase() === 'сот вход'
      && /укажите кол-во касс, на которых отсутствует позиция/i.test(q.text)
    );
    if (!skuQuestion) {
      continue;
    }

    if (/SKU_.*_SIS/.test(q.code)) {
      countJtiSku(stats.shelf, q, resultData);
    }

    if (/SKU_.*_OH/.test(q.code)) {
      countJtiSku(stats.box, q, resultData);
    }

    if (/SKU_.*_Judo/.test(q.code)) {
      countJtiSku(stats.judo, q, resultData);
    }

    if (/SKU_.*_OH\+Judo/.test(q.code)) {
      countJtiSku(stats.boxJudo, q, resultData);
    }
  }

  for (const v of _.values(stats)) {
    v.sku = v.sku.length;
  }

  return stats;
}

function formatStat(n) {
  return _.isNil(n) ? '—' : `${_.round(n, 2)}%`;
}

export default
function JtiStatsTab({ task }) {
  const { shelf, box, judo, boxJudo } = RRU.useMemoArgs(computeJtiStats, task);

  const shelfValue = (
    shelf.pos && shelf.sku
      ? 100 * (1 - shelf.skuValue / (shelf.pos * shelf.sku))
      : null
  );
  const boxValue = (
    box.pos && box.sku
      ? 100 * (1 - box.skuValue / (box.pos * box.sku))
      : null
  );
  const judoValue = (
    judo.pos && judo.sku
      ? 100 * (1 - judo.skuValue / (judo.pos * judo.sku))
      : null
  );
  const boxJudoValue = (
    boxJudo.pos && boxJudo.sku
      ? 100 * (1 - boxJudo.skuValue / (boxJudo.pos * boxJudo.sku))
      : null
  );

  const totalNumerator = shelf.skuValue + box.skuValue + judo.skuValue + boxJudo.skuValue;
  const totalDenominator = (
    shelf.pos * shelf.sku
    + box.pos * box.sku
    + judo.pos * judo.sku
    + boxJudo.pos * boxJudo.sku
  );
  const total = (
    totalDenominator
      ? 100 * (1 - totalNumerator / totalDenominator)
      : null
  );

  return (
    <div className={styles.questionaryResult}>
      <H5>Дистрибуция</H5>

      <HTMLTable className={styles.questionsTable} bordered>
        <tbody>
          <tr>
            <td>Дистрибуция общая</td>
            <td>{formatStat(total)}</td>
          </tr>

          <tr>
            <td>Дистрибуция шкаф</td>
            <td>{formatStat(shelfValue)}</td>
          </tr>

          <tr>
            <td>Дистрибуция бокс</td>
            <td>{formatStat(boxValue)}</td>
          </tr>

          <tr>
            <td>Дистрибуция джудо</td>
            <td>{formatStat(judoValue)}</td>
          </tr>

          <tr>
            <td>Дистрибуция бокс+джудо</td>
            <td>{formatStat(boxJudoValue)}</td>
          </tr>
        </tbody>
      </HTMLTable>
    </div>
  );
}

JtiStatsTab.propTypes = {
  task: PropTypes.object.isRequired,
};
